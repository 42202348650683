<template>
  <div class="market" :class="{ hideCon: showInfo }">
    <h5Top />
    <div class="node-title">
      <!-- <el-carousel height="360px" :indicator-position="bannerList.length > 1 ? 'inside' : 'none'">
        <el-carousel-item v-for="item in bannerList" :key="item.id">
          <img :src="item.imgUrl" alt="" class="bannerimg" @click="handlerBanner(item)" />
        </el-carousel-item>
      </el-carousel> -->
    </div>
    <div class="h5-search flex animate__animated animate__fadeInUp">
      <div class="user-land flex" @click="$router.push('/mystake')">
        <div>{{ $t('stake.desc14') }}</div>
        <img src="../../public/images/land/planeticon.png" alt="" />
      </div>
    </div>
    <div class="market-con">
      <div class="market-title">{{ $t('stake.desc15') }}</div>
      <div class="market-list flex animate__animated animate__fadeInUp animate__delay-1s">
        <div class="palnet" v-for="item,index in list" :key="item.id" :class="[`levelbg${index}`]">
          <!-- <div class="speedtag flex" v-if="item.productStatus">
            <img src="../../public/images/new/speedicon.png" alt="" />
            <div>{{ $t('stake.desc16') }}</div>
          </div> -->
          <div class="planet-con flex">
            <div class="h5level-name flex">
              {{item.name}}
            </div>
            <img :src="item.ico" alt="" class="planet-logo" />
            <div class="con-info">
              <div class="con-name">{{item.name}}</div>
              <div class="info-list flex">
                <div class="list">
                  <div>{{ $t('stake.desc17') }}</div>
                  <p>{{ item.lockType }} {{ $t('stake.desc97') }}</p>
                </div>
                <div class="list">
                  <div>{{ $t('stake.desc18') }}</div>
                  <p>{{ item.priceMin }}~{{ item.priceMax }}</p>
                </div>
                <div class="list">
                  <div>{{ $t('stake.desc19') }}</div>
                  <p>{{ item.apy }}%</p>
                </div>
                <div class="list">
                  <div>{{ $t('stake.desc20') }}</div>
                  <p>{{ item.stakedCoinName }}</p>
                </div>
              </div>
              <div class="info-btn" @click="handlerInfo(item.id)">
                {{ $t('stake.desc21') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="no-data" v-if="showNull">
        <img src="../../public/images/no-data.png" alt="" />
        <span>{{ $t("record.desc13") }}</span>
      </div>
    </div>
    <!-- 质押窗口 -->
    <stakeInfo :productId="productId" @handlerClose="handlerClose" v-if="showInfo" />
    <div id="footer">
      <glFooter :act="1" />
    </div>
    <PageLoading v-model:show="showLoading" />
  </div>
</template>

<script>
import clipboard from "@/utils/libs/clipboard";
import stakeInfo from "@/componets/stakeInfo.vue";
import glFooter from "@/componets/footer.vue";
import h5Top from "@/componets/h5top.vue";
import productIco1 from '@/public/images/stake/product-ico0.svg';
import productIco2 from '@/public/images/stake/product-ico1.svg';
import productIco3 from '@/public/images/stake/product-ico2.svg';
import productIco4 from '@/public/images/stake/product-ico3.svg';
export default {
  components: {
    h5Top,
    stakeInfo,
    glFooter,
  },
  data() {
    return {
      showTips: false,
      bannerList: [],
      list: [],
      showNull: false,
      showInfo: false, //质押窗口
      productId: "",
      levelId: 9,
      planetId:28,
      showLoading: true,
      ismobile: 0,
      lang: '',
    };
  },
  mounted() {
    this.ismobile = localStorage.getItem('ismobile');
    this.lang = localStorage.getItem('language')
    this.getlist();
    this.handleInit();
  },
  methods: {
    handlerBanner(info) {
     
    },
    async handleInit() {
      try {
        let bannerres = null;
        if (window.screen.width > 767) {
          bannerres = await this.$post(this.URL.home.banner, {
            positionKey: "MARKET_NODE_BANNER",
          });
        } else {
          bannerres = await this.$post(this.URL.home.banner, {
            positionKey: "MARKET_NODE_BANNER_H5",
          });
        }
        if (bannerres.code == 0) {
          this.bannerList = bannerres.data;
        }
        this.showLoading = false;
      } catch (error) {
        this.showLoading = false;
      }
    },
    handlerInfo(id) {
      if (localStorage.getItem("token") == null) {
        this.$router.push("/login");
        return;
      }
      this.productId = id;
      this.showInfo = true;
    },
    handlerClose(val) {
      this.showInfo = false;
      if (val == 1) {
        this.getlist();
      }
    },
    copy(e, text) {
      clipboard(e, text);
      this.$message.success(this.$t("planet.desc61"));
    },
    //获取产品列表
    async getlist() {
      const res = await this.$post(this.URL.stake.productList, {});
      if (res.code == 0) {
        this.list = res.data.filter((model)=>{
          let imgUrl = '';
          if(model.id==1){
            imgUrl =productIco1; 
          }else if(model.id==2){
            imgUrl =productIco2; 
          }else if(model.id==3){
            imgUrl =productIco3; 
          }else{
            imgUrl =productIco4; 
          }
          model.ico = imgUrl;
          return model
        })
        if (this.list.length == 0) {
          this.showNull = true;
        }
      }
    }
  },
};
</script>

<style lang="less" scoped>
::v-deep .van-popup {
  border-radius: 20px;
  overflow-y: initial;
}

.notice-con {
  position: relative;
  width: 684px;
  padding: 40px 0;
  max-height: 653px;
  background: url("../../public/images/reward/rulesbg.png") top no-repeat;
  background-size: 100% 653px;

  &::after {
    content: '';
    position: absolute;
    top: -30px;
    right: 45px;
    width: 154px;
    height: 158px;
    background: url("../../public/images/reward/rules.png") top no-repeat;
    background-size: 100% 100%;
  }

  .close {
    position: absolute;
    bottom: -58px;
    left: 50%;
    width: 38px;
    height: 38px;
    transform: translateX(-50%);
    cursor: pointer;
  }

  .notice-name {
    padding: 20px 40px 0;
    margin-bottom: 32px;
    font-size: 24px;
    color: #c9fa5b;
    line-height: 24px;
  }

  .notice-title {
    padding: 0 40px 12px;
    font-size: 18px;
    color: #ffffff;
  }


  .rules-list {
    max-height: 430px;
    overflow-y: scroll;
    padding: 0 40px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);

    div {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      label {
        color: #C9FA5B;
      }
    }

    &::-webkit-scrollbar {
      width: 0px; //修改滚动条宽度
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #666666;
    }

    .rules-table {

      .table-title {
        padding: 20px;
        background: #232323;
        margin-bottom: 0;

        div {
          flex: 0 0 20%;
          font-size: 13px;
          color: rgba(255, 255, 255, 0.6);
          margin-bottom: 0;

          &:nth-child(2) {
            flex: 1;
          }

          &:last-child {
            flex: 0 0 30%;
            text-align: right;
          }
        }
      }

      .tablle-info {
        padding: 20px;
        margin-bottom: 0;

        div {
          flex: 0 0 20%;
          font-size: 13px;
          color: #fff;
          margin-bottom: 0;

          &:nth-child(2) {
            flex: 1;
          }

          &:last-child {
            flex: 0 0 30%;
            text-align: right;
          }
        }
      }
    }

    .montitle {
      padding: 20px;
      background: #232323;
      margin-bottom: 0;

      div {
        flex: 0 0 50%;
        margin-bottom: 0;
        font-size: 13px;
        color: rgba(255, 255, 255, 0.6);

        &:last-child {
          text-align: right;
        }
      }
    }

    .montime {
      padding: 20px;

      div {
        flex: 0 0 50%;
        margin-bottom: 0;
        font-size: 13px;
        color: #fff;

        &:last-child {
          text-align: right;
        }
      }
    }
  }

  .notice-time {
    padding: 23px 40px 0;
    font-size: 16px;
    text-align: right;
    color: rgba(255, 255, 255, 0.6);
  }

  .notice-btn {
    margin: 23px auto 0;
    width: 418px;
    height: 52px;
    background: url("../../public/images/new/btnbg25.png") center no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    font-size: 18px;
    color: #000000;
    text-align: center;
    line-height: 52px;
  }
}

.market {
  position: relative;
  padding: 96px 0 80px;

  .node-title {
    .bannerimg {
      width: 100%;
      height: 360px;
      object-fit: cover;
    }
  }

  .market-con {
    width: 1240px;
    margin: 0 auto;

    .market-reviset {
      margin-top: 12px;

      .reviset-notice {
        flex: 1;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 36px;

        span {
          color: #C9FA5B;
        }

        img {
          flex: 0 0 20px;
          width: 20px;
          height: 20px;
          margin: 8px 10px 0 0;
        }

        div {
          height: 36px;
          // display: block;
        }

        p {
          display: none;
        }
      }

      .reviset-btn {
        .btninfo {
          max-width: 218px;
          height: 36px;
          margin-right: 14px;
          background: #181818;
          border-radius: 20px;
          cursor: pointer;
          padding: 0 20px;
          font-size: 13px;
          color: #FFFFFF;

          &:last-child {
            margin-right: 0;
          }

          img {
            width: 20px;
            height: 20px;
            margin: 0 7px 0 0;
          }
        }
      }
    }

    .market-title {
      padding: 32px 0 24px;
      font-size: 24px;
      color: #ffffff;
    }

    .market-search {
      flex-wrap: wrap;

      .search-item {
        flex: 0 0 160px;
        margin-right: 20px;

        &:nth-child(5) {
          flex: 0 0 326px;
          margin-right: 0;

          p {
            width: 100%;
            border: 0;
          }

          input {
            width: 49%;
            padding: 0 14px;
            height: 40px;
            font-size: 16px;
            color: #fff;
            border: 1px solid rgba(255, 255, 255, 0.2);
            box-sizing: border-box;

            &::placeholder {
              color: rgba(255, 255, 255, 0.4);
            }

            &:last-child {
              margin-left: 14px;
            }
          }
        }

        div {
          margin-bottom: 10px;
          font-size: 13px;
          color: #a8a8a8;
        }

        p {
          width: 100%;
          height: 40px;
          border: 1px solid rgba(255, 255, 255, 0.2);

          ::v-deep .el-select {
            width: 100%;
            height: 38px;
            color: #fff;

            .el-input {
              height: 38px;

              .el-input__wrapper {
                background-color: rgba(255, 255, 255, 0);
                box-shadow: none !important;
              }

              .el-input__inner {
                color: #fff;
              }
            }
          }
        }
      }

      .search-input {
        margin-top: 24px;
        flex: 0 0 338px;
        height: 40px;

        input {
          width: 100%;
          padding: 0 14px;
          height: 40px;
          font-size: 16px;
          color: #fff;
          border: 1px solid rgba(255, 255, 255, 0.2);
          box-sizing: border-box;

          &::placeholder {
            color: rgba(255, 255, 255, 0.4);
          }
        }
      }
    }

    .market-list {
      margin-top: 24px;
      flex-wrap: wrap;

      .palnet {
        position: relative;
        flex: 0 0 612px;
        height: 316px;
        margin-bottom: 14px;
        margin-right: 15px;
        background: url("../../public/images/land/bg1.png") no-repeat center;
        background-size: 108% auto;
        border-radius: 16px;
        overflow: hidden;
        border: 2px solid rgba(161, 246, 75, 0);
        box-sizing: border-box;
        cursor: pointer;

        .speedtag {
          position: absolute;
          top: 25px;
          left: 20px;
          min-width: 104px;
          height: 26px;
          border-radius: 8px;
          z-index: 56;
          background: linear-gradient(180deg, rgba(160, 246, 75, 0.60) -10.71%, rgba(50, 100, 20, 0.60) 84.58%);

          img {
            flex: 0 0 30px;
            width: 30px;
            height: 31px;
            margin: -5px 0 0 -16px;
          }

          div {
            flex: 1;
            padding-left: 6px;
            font-size: 14px;
            color: #C9FA5B;
            line-height: 24px;
          }
        }

        &:nth-child(2n) {
          margin-right: 0;
        }

        &:hover {
          border: 2px solid #d2fb71;

          &::after {
            content: "";
            position: absolute;
            top: 12px;
            left: 12px;
            width: 589px;
            height: 292px;
            background: url("../../public/images/new/markettag.png") center no-repeat;
            background-size: 100% 100%;
            z-index: 4;
          }

          .planet-con {
            .con-info {
              transition: 1s;
              margin-top: -68px;

              .info-btn {
                display: block;
              }
            }
          }
        }

        .planet-con {
          position: relative;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 316px;
          overflow: hidden;
          z-index: 5;

          .h5level-name {
            display: none;
          }

          .sales-end {
            position: absolute;
            bottom: 32px;
            left: 32px;
            width: 98px;
            height: 80px;
            background: url("../../public/images/new/saleend.png") center no-repeat;
            background-size: 100% 100%;
            font-size: 16px;
            color: #ffffff;
            z-index: 3;

            p {
              text-align: center;
              line-height: 80px;
              transform: rotate(-11deg);
            }
          }

          .planet-logo {
            flex: 0 0 200px;
            width: 200px;
            height: 200px;
            margin: 68px 24px 0 32px;
          }

          .con-info {
            flex: 1;
            padding-top: 40px;
            margin-right: 40px;
            transition: 1s;

            .con-name {
              padding: 0 0 16px;
              font-size: 28px;
              color: #ffffff;
              line-height: 28px;
            }

            .con-level {
              flex-wrap: wrap;

              .level-name {
                font-size: 14px;
                color: rgba(255, 255, 255, 0.8);
                line-height: 20px;

                p {
                  color: #fff;

                  img {
                    flex: 0 0 12px;
                    width: 12px;
                    height: 12px;
                    cursor: pointer;
                    margin: 4px 0 0 4px;
                  }
                }
              }

              .level-tag {
                padding: 0 6px;
                margin-left: 12px;
                background: rgba(201, 250, 91, 0.2);
                height: 20px;
                font-size: 12px;
                color: #c9fa5b;
                text-align: center;
                line-height: 20px;
                border-radius: 4px;
              }
            }

            .con-team {
              margin: 11px 10px 0 0;

              p {
                padding: 4px 8px;
                background: rgba(201, 250, 91, 0.2);
                border-radius: 4px;
                font-size: 12px;
                color: #c9fa5b;
                line-height: 12px;
              }
            }

            .tagrate {
              margin-top: 11px;
              padding: 4px 8px;
              justify-content: center;
              font-size: 12px;
              color: #5B88FA;
              border-radius: 4px;
              background: rgba(91, 136, 250, 0.20);
              line-height: 12px;

              img {
                width: 12px;
                height: 12px;
                margin: 0 0 0 4px;
              }
            }

            .info-list {
              margin-top: 16px;
              padding: 20px 32px;
              background: linear-gradient(180deg,
                  rgba(161, 246, 75, 0.6) 0%,
                  rgba(50, 100, 20, 0.6) 97.92%);
              border-radius: 8px;
              flex-wrap: wrap;

              .list {
                flex: 0 0 50%;
                font-size: 13px;
                color: #c9fa5b;

                &:nth-last-child(-n + 2) {
                  margin-top: 18px;
                }

                p {
                  margin-top: 8px;
                  color: #fff;
                  font-size: 14px;
                }
              }
            }

            .info-btn {
              margin-top: 24px;
              height: 44px;
              background: url("../../public/images/new/btnbg27.png") center no-repeat;
              background-size: 100% 100%;
              font-size: 16px;
              color: #000000;
              text-align: center;
              line-height: 44px;
              display: none;
            }
          }
        }
      }

      .nobtn {
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          z-index: 8;
        }

        &:hover {
          border: 2px solid rgba(161, 246, 75, 0);

          &::after {
            display: none;
          }

          .planet-con {
            .con-info {
              transition: 1s;
              margin-top: 0;

              .info-btn {
                display: none;
              }
            }
          }
        }
      }

      .levelbg0 {
        background-image: url("../../public/images/stake/product-bj0.svg");
        background-size: 108% auto;
      }

      .levelbg1 {
        background-image: url("../../public/images/stake/product-bj1.svg");
        background-size: 108% auto;
      }

      .levelbg2 {
        background-image: url("../../public/images/stake/product-bj2.svg");
        background-size: 108% auto;
      }

      .levelbg3 {
        background-image: url("../../public/images/stake/product-bj3.svg");
        background-size: 108% auto;
      }



      .levelbg6 {
        background-image: url("../../public/images/land/bg6.png");
        background-size: 108% auto;
      }

      .levelbg7 {
        background-image: url("../../public/images/land/bg7.png");
        background-size: 108% auto;
      }
    }

    .planet-page {
      padding: 0;

      ::v-deep .el-pagination {
        button {
          background-color: rgba(255, 255, 255, 0);
          color: #666666;
          font-size: 14px;
        }

        .el-pager {
          li {
            background: rgba(255, 255, 255, 0);
            font-size: 14px;
            color: #666666;
          }

          .is-active {
            background: #c9fa5b !important;
            border-radius: 4px !important;
            color: #000000 !important;
          }
        }
      }
    }
  }
}

.hideCon {
  height: 100vh;
  overflow: hidden;
}

#footer {
  display: none;
}

.h5-search {
  display: none;
}

.search-con {
  position: relative;
  padding: 20px 16px;
  background: #181818;

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 18px;
    height: 18px;
  }

  .search-title {
    padding-bottom: 20px;
    font-size: 18px;
    color: #fff;
    font-weight: 600;
  }

  .search-list {
    max-height: 400px;
    overflow-y: scroll;

    .list-item {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;

        .list-info {
          justify-content: space-between;

          p {
            width: 12px;
            height: 1px;
            background: #242424;
            margin: 19px 0 0;
          }

          div {
            flex: 0 0 44%;

            input {
              width: calc(100% - 8px);
              margin-left: 8px;
              height: 40px;
              font-size: 13px;
              color: #fff;

              &::placeholder {
                color: rgba(255, 255, 255, 0.6);
              }
            }
          }
        }
      }

      .list-name {
        margin-bottom: 12px;
        font-size: 15px;
        color: #ffffff;
        line-height: 16px;
      }

      .list-info {
        flex-wrap: wrap;

        div {
          flex: 0 0 32%;
          margin-right: 2%;
          margin-top: 10px;
          height: 40px;
          background: #242424;
          border-radius: 30px;
          font-size: 13px;
          color: #ffffff;

          &:nth-child(-n + 3) {
            margin-top: 0;
          }

          &:nth-child(3n) {
            margin-right: 0;
          }
        }

        .on {
          background: #c9fa5b;
          color: #000;
        }
      }
    }
  }

  .search-btn {
    margin-top: 20px;
    justify-content: space-between;

    div {
      flex: 0 0 48.5%;
      height: 52px;
      background: url("../../public/images/land/btnbg14.png") center no-repeat;
      background-size: 100% 100%;
      font-size: 16px;
      color: #ffffff;

      &:last-child {
        background: url("../../public/images/land/btnbg15.png") center no-repeat;
        background-size: 100% 100%;
        color: #000000;
      }
    }
  }
}

.no-data {
  width: 100%;
  padding: 130px 0;

  img {
    display: block;
    width: 120px;
    height: 120px;
    margin: 0 auto 16px;
  }

  span {
    display: block;
    margin: 0 auto;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #a1a1a1;
  }
}

.modal-con {
  position: relative;
  padding: 32px;
  width: 480px;

  .close {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .modal-title {
    margin-bottom: 32px;
    font-size: 20px;
    color: #ffffff;
  }

  .modal-desc {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
  }

  .divestment-list {
    padding: 20px;
    border-radius: 8px;
    background: #242424;
    flex-wrap: wrap;

    .divestment-info {
      margin-bottom: 16px;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      line-height: 14px;

      &:last-child {
        margin-bottom: 0;
      }

      div {
        flex: 1;
      }

      p {
        flex: 0 0 40%;
        color: #fff;
        text-align: right;
      }
    }
  }

  .modal-tipsname {
    margin: 21px 0 11px;
    font-size: 14px;
    color: #fff;
    line-height: 16px;

    img {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
  }

  .modal-tips1 {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
    line-height: 14px;

    div {
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .modal-input {
    margin: 20px 0 16px;
  }

  .modal-tips {
    font-size: 14px;
    color: #eb4343;
  }

  .modal-btncon {
    margin-top: 24px;
    height: 50px;
    background: url("../../public/images/new/btnbg16.png") center no-repeat;
    background-size: 100% 100%;
    font-size: 16px;
    color: #000000;
    cursor: pointer;
  }

  .modal-btn {
    margin-top: 32px;
    justify-content: space-between;

    div {
      flex: 0 0 197px;
      height: 50px;
      background: url("../../public/images/new/btnbg14.png") center no-repeat;
      background-size: 100% 100%;
      font-size: 18px;
      color: #ffffff;
      cursor: pointer;

      &:last-child {
        background: url("../../public/images/new/btnbg15.png") center no-repeat;
        background-size: 100% 100%;
        color: #000000;
      }
    }
  }
}

.ratecon {
  width: 816px;

  .rate-list {
    font-size: 16px;
    color: #FFFFFF;

    div {
      margin-bottom: 24px;
    }
  }

  .rate-table {
    border: 1px solid rgba(255, 255, 255, 0.08);
    box-sizing: border-box;

    .table-thead {
      background: rgba(201, 250, 91, 0.10);

      div {
        flex: 0 0 25%;
        height: 39px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.85);
        text-align: center;
        border-right: 1px solid rgba(255, 255, 255, 0.08);

        &:last-child {
          border-right: 0;
        }
      }
    }

    .tbody-info {
      border-top: 1px solid rgba(255, 255, 255, 0.08);

      div {
        flex: 0 0 25%;
        height: 39px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.85);
        text-align: center;
        border-right: 1px solid rgba(255, 255, 255, 0.08);
        line-height: 39px;
        &:last-child {
          border-right: 0;
        }
      }
    }
  }
  .ratebtn{
    width: 500px;
    height: 55px;
    margin: 24px auto 0;
    border-radius: 6px;
    background: #C9FA5B;
    font-size: 20px;
    color: #282626;
    font-weight: 600;
    cursor: pointer;
  }
}
.activity-wrap{
  margin-top: 48px;
  .wrap-item{
    flex: 1;
    padding: 20px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    &:nth-child(2){
      margin-left: 20px;
    }
    &:nth-child(3){
      margin-top: 16px;
    }
    .img1{
      flex: 0 0 72px;
      width: 72px;
      height: 72px;
      margin-right: 24px;
    }
    .item-info{
      flex: 1;
      font-size: 20px;
        line-height: 24px;
      .info-name{

        color: #C9FA5B;
        img{
          width: 24px;
          height: 24px;
          margin-left: 12px;
        }
      }
      .info-desc{
      
        margin-top: 16px;
        color: rgba(255, 255, 255, 0.8);
      }
    }
    .img2{
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
      margin:24px 0 0 24px;
      cursor: pointer;
    }
  }
}
@media (max-width: 1200px) {
  .market {
    padding: 68px 0 80px;

    .market-con {
      width: calc(100vw - 8px);

      .market-reviset {
        margin-top: 12px;

        .reviset-notice {
          flex: 1;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 36px;

          div {
            flex: 1;
          }
        }

        .reviset-btn {
          margin-top: 10px;

          .btninfo {
            padding: 0;
            width: 36px;

            div {
              display: none;
            }

            img {
              margin: 0;
            }
          }
        }
      }

      .market-title {
        padding: 16px 0 12px;
      }

      .market-search {
        .search-item {
          margin-right: 12px;

          &:nth-child(-n + 5) {
            margin-bottom: 16px;
          }
        }

        .search-input {
          flex: 1;
          margin-left: 12px;
          margin-top: 27px;
        }
      }

      .market-list {
        .palnet {
          flex: 0 0 100%;
          margin-right: 0;

          .speedtag {
            top: inherit;
            bottom: 26px;
            height: 20px;
            min-width: 79px;

            div {
              padding-left: 4px;
              font-size: 10px;
              line-height: 18px;
            }

            img {
              flex: 0 0 20px;
              width: 20px;
              height: 20px;
              margin: -1px 0 0 -3px;
            }
          }

          &:hover {
            &::after {
              background: none;
            }
          }

          .planet-con {
            position: relative;

            .h5level-name {
              position: absolute;
              top: 16px;
              left: 12px;
              display: flex;
              padding: 0 6px;
              background: rgba(248, 164, 48, 0.2);
              height: 20px;
              font-size: 12px;
              color: #f8a430;
              text-align: center;
              line-height: 20px;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
  .activity-wrap{
    margin: 12px 0;
    width: 100%;
    justify-content: left;
    .wrap-item{
      flex: 1;
      padding: 12px;
      &:nth-child(2){
        margin-left: 24px;
      }
      .img1{
        flex: 0 0 48px;
        width: 48px;
        height: 48px;
        margin-right: 12px;
      }
      .item-info{
        font-size: 14px;
        line-height: normal;
        .info-name{
          img{
            width: 14px;
            height: 14px;
          }
        }
        .info-desc{
          margin-top: 4px;
        }
      }
      .img2{
        margin: 12px 0 0 12px;
      }
    }
  }
}

@media (max-width: 767px) {
  .market {
    padding: 56px 0 100px;
    background: #000;

    .node-title {
      ::v-deep .el-carousel__container {
        height: 152px !important;
      }

      .bannerimg {
        width: 100%;
        height: 152px;
        object-fit: cover;
      }
    }

    .market-con {
      .market-reviset {
        margin-top: 12px;
        flex-wrap: wrap;

        .reviset-notice {
          flex: 1;
          height: 32px;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 32px;

          img {
            margin-top: 6px;
          }

          div {
            height: 32px;
          }
        }

        .reviset-btn {
          margin-top: 6px;
          justify-content: flex-end;

          .btninfo {
            padding: 0;
            width: 20px;
            height: 20px;
            margin-right: 6px;

            div {
              display: none;
            }

            img {
              width: 20px;
              height: 20px;
              margin: 0;
            }
          }
        }
      }

      .market-title,
      .market-search {
        display: none;
      }

      .market-list {
        margin-top: 16px;

        .palnet {
          height: inherit;
          background-size: auto;
          border: 0;

          &:hover {
            border: 0;

            .planet-con {
              .con-info {
                transition: 1s;
                margin-top: 0;
              }
            }
          }

          .planet-con {
            position: relative;
            height: inherit;
            padding-bottom: 20px;

            .sales-end {
              bottom: 12px;
              left: 12px;
              width: 73px;
              height: 60px;

              p {
                line-height: 60px;
                font-size: 12px;
              }
            }

            .planet-logo {
              flex: 0 0 120px;
              width: 120px;
              height: 120px;
              margin: 48px 17px 0 12px;
            }

            .con-info {
              padding-top: 16px;
              margin-right: 12px;

              .con-name {
                padding-bottom: 10px;
                font-size: 18px;
                line-height: 18px;
              }

              .con-level {
                .level-name {
                  font-size: 12px;
                  line-height: 12px;

                  p {
                    img {
                      margin: 0 0 0 4px;
                    }
                  }
                }

                .level-tag {
                  display: none;
                }
              }

              .info-list {
                padding: 0;
                background: none;

                .list {
                  font-size: 12px;
                  color: rgba(255, 255, 255, 0.6);

                  p {
                    margin-top: 6px;
                  }

                  &:nth-last-child(-n + 2) {
                    margin-top: 12px;
                  }
                }
              }

              .info-btn {
                width: 152px;
                margin-top: 16px;
                display: block;
                height: 36px;
                font-size: 14px;
                background: url("../../public/images/new/btnbg28.png") center no-repeat;
                background-size: 100% 100%;
                line-height: 36px;
              }
            }
          }
        }
      }
    }
  }

  .modal-con {
    width: 100%;
    padding: 20px;

    .close {
      top: 20px;
      right: 20px;
    }

    .modal-title {
      font-size: 16px;
      margin-bottom: 20px;
    }

    .modal-btn {
      div {
        flex: 0 0 48%;
        height: 48px;
      }
    }
  }
  #footer {
    display: block;
  }

  .h5-search {
    display: flex;

    input {
      flex: 0 0 180px;
      height: 40px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      box-sizing: border-box;
      padding: 11px 14px;
      font-size: 14px;
      color: #fff;

      &::placeholder {
        color: rgba(255, 255, 255, 0.4);
      }
    }

    .user-land {
      flex: 1;
      margin: 0 12px 0 0;
      line-height: 40px;
      font-size: 16px;
      color: #c9fa5b;
      justify-content: flex-end;

      img {
        width: 20px;
        height: 20px;
        margin: 10px 0 0 6px;
      }
    }

    .search-icon {
      flex: 0 0 20px;
      width: 20px;
      height: 20px;
      margin-top: 10px;
    }
  }

  .no-data {
    width: 100%;
    padding: 40px 0;

    img {
      display: block;
      width: 120px;
      height: 120px;
      margin: 0 auto 16px;
    }

    span {
      display: block;
      margin: 0 auto;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #a1a1a1;
    }
  }
  .activity-wrap{
    overflow-y: scroll;
    .wrap-item{
      flex: 0 0 304px;
      &:nth-child(3){
        margin-top: 0;
      }
    }
  }
}
</style>
