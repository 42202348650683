<template>
  <div class="nodewrappar" :class="[
    showCon
      ? 'animate__animated animate__zoomIn'
      : 'animate__animated animate__zoomOut',
    `bg${levelId}`,
  ]">
    <div class="stardetails-bg flex" :style="{ paddingBottom:'58px'}">
      <div class="closeicon" @click="handlerClose(0)"></div>
      <!-- <div class="sales-end h5top">
        <p>{{ $t("market.desc75") }}</p>
      </div> -->
      <!-- <div class="slider-center h5top">
        <div class="center-top animate__animated animate__zoomIn" :class="{ animate__zoomOut: startId == 2 }"
          v-show="startId == 1" @click="handlerSwitch">
          <star-img :idNameBF="idNameBF" :sphereImg="'https://daoversal-test.oss-cn-hongkong.aliyuncs.com/upload/20230714/54110f2e8cfc13399e3c7add8f98a5e3.png'" :starWidth="80" :starHeight="80" />
        </div>
        <div class="center-top animate__animated animate__zoomIn" @click="handlerSwitch" v-show="startId == 2">
          <img :src="'https://daoversal-test.oss-cn-hongkong.aliyuncs.com/upload/20230827/f95f953a6bc40fd357c50b47188516ab.png'" alt="" />
        </div>
        <div class="center-switch" :class="[`switch${levelId}`]" @click="handlerSwitch"></div>
        <div class="center-bottom animate__animated animate__zoomIn" :class="{ animate__zoomOut: startId == 2 }" :style="{marginTop: '16px'}" v-show="startId == 1">
          <img :src="'https://daoversal-test.oss-cn-hongkong.aliyuncs.com/upload/20230827/dc3717214d56bb2536c9b6e669b210d3.png'" alt="" />
        </div>
        <div class="center-bottom animate__animated animate__zoomIn" v-show="startId == 2">
          <star-img :idNameBF="idNameBF" :sphereImg="'https://daoversal-test.oss-cn-hongkong.aliyuncs.com/upload/20230714/54110f2e8cfc13399e3c7add8f98a5e3.png'" :starWidth="340" :starHeight="340" />
        </div>
      </div>
      <div class="slider-left animate__animated animate__bounceIn animate__delay-0.5s">
        <div class="left-name morename">
          您正在质押{{productInfo?.name}}这款产品
        </div>
        
        <div class="marketbig-info">
          <div class="info-wrap">
            <div class="info-name">Galaxy Manifesto</div>
            <div class="info-user">
              {{productInfo?.title}}
            </div>
            <div class="info-subtitle">
              {{ productInfo.description }}
            </div>
          </div>
          <div class="info-list flex">
            <div class="info-desc">
              <div class="flex">Name</div>
              <p>{{productInfo?.name}}</p>
            </div>
            <div class="info-desc">
              <div>Range</div>
              <p>{{productInfo?.priceMin}} ~ {{productInfo?.priceMax}}</p>
            </div>
            <div class="info-desc">
              <div>Apy</div>
              <p>{{ productInfo?.apy }}%</p>
            </div>
            <div class="info-desc">
              <div>Period</div>
              <p>30 ~ 360 Day</p>
            </div>
            <div class="info-desc">
              <div>Currency</div>
              <p>{{productInfo?.payCoinName}}</p>
            </div>
           
          </div>
        </div>
      </div> -->
      <!-- <div class="slider-center"> -->
        <!-- <div class="center-top animate__animated animate__zoomIn" @click="handlerSwitch" :class="{ animate__zoomOut: startId == 2 }" v-show="startId == 1">
          <star-img :idNameBF="idNameBF" :sphereImg="'https://daoversal-test.oss-cn-hongkong.aliyuncs.com/upload/20230714/54110f2e8cfc13399e3c7add8f98a5e3.png'" :starWidth="140" :starHeight="140" />
        </div> -->
        <!-- <div class="center-top animate__animated animate__zoomIn" @click="handlerSwitch" v-show="startId == 2">
          <img :src="'https://daoversal-test.oss-cn-hongkong.aliyuncs.com/upload/20230827/f95f953a6bc40fd357c50b47188516ab.png'" alt="" />
        </div>
        <div class="center-switch" :class="[`switch${levelId}`]" @click="handlerSwitch"></div> -->
        <!-- <div class="center-bottom animate__animated animate__zoomIn" :class="{ animate__zoomOut: startId == 2 }" >
          <img :src="bjIco" alt="" />
        </div> -->
        <!-- <div class="center-bottom animate__animated animate__zoomIn" :style="{marginTop:  startId== 2 ? '16px' : '16px',}" v-show="startId == 2">
          <star-img :idNameBF="idNameBF" :sphereImg="'https://daoversal-test.oss-cn-hongkong.aliyuncs.com/upload/20230714/54110f2e8cfc13399e3c7add8f98a5e3.png'" :starWidth="520" :starHeight="520" />
        </div> -->
      <!-- </div> -->
      <div class="slider-right animate__animated animate__bounceIn animate__delay-0.5s">
       
        <div class="right-name">{{ $t("market.desc24") }}</div>
        <div class="right-tab flex">
          <div :class="[payact == 1 ? `tabbgon${levelId}` : `tabbg${levelId}`]" class="flexcenter tabbg1"
            @click="handlerTab(1)">
            {{ $t("node.desc27") }}
          </div>
          <div :class="[payact == 2 ? `tabbgon${levelId}` : `tabbg${levelId}`]" class="flexcenter tabbg1"
            @click="handlerTab(2)">
            {{ $t("node.desc28") }}
          </div>
        </div>
        
        <div class="right-name">{{ $t('stake.desc79') }}</div>
        <div class="right-input flex">
          <div class="payinfo flex">
            <img :src="'https://daoversal-test.oss-cn-hongkong.aliyuncs.com/upload/20230713/00547c814e48498b6c88999350292d3c.png'" alt="" />
            {{productInfo?.stakedCoinName }}
          </div>
        </div>
        <div class="right-name">
          {{payact==1?$t('stake.desc80'):$t('stake.desc81')}}
        </div>
        <div class="right-input flex">
          <div class="payinfo flex">
            {{acountBalance}} {{productInfo?.payCoinName }}
          </div>
        </div>
        
        <div class="slider-tips" v-if="acountBalance<Number(productInfo?.priceMin)">
          {{ $t("market.desc28") }}
          <span @click="$router.push(`/asset`)">{{ $t("node.desc42") }}</span>
        </div>
        

        <div class="right-name flex">
          <div>{{ $t('stake.desc82') }}</div>
          <p>{{ $t('stake.desc83') }}: {{ productInfo?.amount}}</p>
        </div>
        <div class="right-input flex">
          <input type="text"  v-model="onePledgeAmount" :placeholder="productInfo?.priceMin + '~'+ productInfo?.priceMax" @change="onChange" />
          {{productInfo?.stakedCoinName }}
        </div>
        
        <div class="right-name">
          <div>{{ $t('stake.desc84') }}</div>
        </div>
        <div class="right-numinput flex">
          <div class="lockType-card" v-for="item,index in lockTypeData" :class="item.id==lockType?'lockType-active':''" @click="lockType=item.id">{{item.name}}{{ $t('stake.desc97') }}</div>
          <!-- <div :style="{color:pledgeNum<30?'#D9D9D9':'#ffffff'}" @click="handlerAdd(1)">-</div>
          <input type="tel" v-model="pledgeNum" @change="onChangeNum" onkeyup="this.value=this.value.replace(/\D/g,'')"/>
          <div :style="{color:pledgeNum<360?'#ffffff':'#D9D9D9'}" @click="handlerAdd(2)">+</div> -->
        </div>
        <!-- 质押协议 -->
        <div class="slider-check flex">
          <!-- <img st :src="check ? require(`../public/images/land/check${levelId}.png`) : require('../public/images/new/checkimg1.png')" alt=""/> -->
          <div>
            <span class="hbs">{{ $t('stake.desc85',{n1:productInfo?.marginRate+'%'}) }}</span>
          </div>
        </div>
        <div style="color: rgba(255, 255, 255, 0.4);margin-top: 12px;">{{ $t('stake.desc94') }} {{totalPaymentAmount}}</div>
        <div class="slider-btn flexcenter" :class="[`btnbg${levelId}`]" @click="showPay">
          {{ $t('stake.desc86') }}
        </div>
      </div> 
    </div>
    <!-- 输入交易密码 -->
    <el-dialog v-model="showpsd" :append-to-body="true">
      <div class="modal-con">
        <img src="../public/images/new/closeicon.png" alt="" class="close" @click="showpsd = false" />
        <div class="modal-title">{{ $t("home.desc7") }}</div>
        <div class="modal-desc">{{ $t("node.desc53") }}</div>
        <div class="modal-input">
          <psdinput @finish="finish" @finish1="finish1" v-if="showpsd" ref="PsdInput" />
        </div>

        <div class="modal-btncon flexcenter" @click="handlerBuy()" v-loading="loading">
          {{ $t("home.desc20") }}
        </div>
        <div class="forgottitle" @click="handlerLogpsd">{{ $t('sign.desc72') }}</div>
      </div>
    </el-dialog>

    <!-- 质押成功提示 -->
    <el-dialog v-model="showSuccess" :append-to-body="true">
      <div class="modal-con">
        <img src="../public/images/new/closeicon.png" alt="" class="close" @click="handlerClose(1)" />
        <div class="modal-title">{{ $t('stake.desc87') }}</div>
        <div class="modal-desc">{{ $t('stake.desc88') }}</div>
        <div class="modal-btn flex">
          <div class="flexcenter" @click="handlerClose(1)">
            {{ $t('stake.desc89') }}
          </div>
          <div class="flexcenter" @click="$router.push('/mystake')">
            {{ $t('stake.desc90') }}
          </div>
        </div>
      </div>
    </el-dialog>
    
    <!-- 资金密码提示 -->
    <el-dialog v-model="showTrade" :append-to-body="true">
      <div class="modal-con">
        <img src="../public/images/new/closeicon.png" alt="" class="close" @click="showTrade = false" />
        <div class="modal-title">{{ $t("node.desc45") }}</div>
        <div class="modal-desc">{{ $t("node.desc46") }}</div>
        <div class="modal-btn flex">
          <div class="flexcenter" @click="showTrade = false">
            {{ $t("node.desc47") }}
          </div>
          <div class="flexcenter" @click="handlerSubmit(1)">
            {{ $t("node.desc48") }}
          </div>
        </div>
        <div class="forgottitle">{{ $t('sign.desc72') }}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import starImg from "./starImg.vue";
import psdinput from "./psdinput.vue";
import bus from "@/utils/bus";
import productBj1 from '@/public/images/stake/stake-product-bj1.svg';
import productBj2 from '@/public/images/stake/stake-product-bj2.svg';
import productBj3 from '@/public/images/stake/stake-product-bj1.svg';
import productBj4 from '@/public/images/stake/stake-product-bj4.svg';
export default {
  props: ["productId"],
  components: {
    starImg,
    psdinput,
  },
  data() {
    return {
      pledgeNum:1, //投资份额
      onePledgeAmount:'', //单份投资金额
      totalPayment:'', //支付总额度
      password: "",
      payact:1, //支付方式
      lockType:0,
      productInfo:{},
      bjIco:'',
      lockTypeData:[{id:0,name:'30'},{id:1,name:'60'},{id:2,name:'90'},{id:3,name:'180'},{id:4,name:'360'}],
      
      showTrade: false,
      showSuccess: false,
      showpsd: false,
      showCon: true,
      levelId:9,
      idNameBF: "",
      
      check: true,
      acountBalance:0, //支付账户余额
      totalBalance:0, //
      
      loading: false,
      userInfo: {},
      startId: 1,
    };
  },
  created() {
    this.getProductInfo();
    
    if (localStorage.getItem("token") != null) {
      this.$post(this.URL.member.info, {}).then((res) => {
        if (res.code == 0) {
          this.userInfo = res.data;
        }
      });
    }
  },
  computed: {
    totalPaymentAmount: function(){
      let result = 0;
      let totalPledgeAmount = 0;
      if(this.onePledgeAmount!=''&&Number(this.onePledgeAmount)>0){
        if(this.onePledgeAmount>Number(this.productInfo?.priceMax)){
          this.onePledgeAmount = this.productInfo?.priceMax;
        }
        if(this.pledgeNum!=''&&Number(this.pledgeNum)>0){
          totalPledgeAmount = this.common.calculate(this.onePledgeAmount,this.pledgeNum,0,'multiply');
        }
        if(Number(totalPledgeAmount)>=Number(this.productInfo?.amount)){
          totalPledgeAmount = this.productInfo?.amount;
        }
        if(Number(totalPledgeAmount)>=this.acountBalance){
          totalPledgeAmount = this.acountBalance
        }
      }
      let maxPercentage = (100 +Number(this.productInfo?.marginRate))/100
      if(this.acountBalance<totalPledgeAmount*maxPercentage){
        totalPledgeAmount = this.common.calculate(this.acountBalance,maxPercentage,4,'divide');
        result = this.acountBalance
      }else{
        result= this.common.calculate(totalPledgeAmount,maxPercentage,4,'multiply');
      }
      if(Number(totalPledgeAmount)>0&&this.pledgeNum>0){
        this.onePledgeAmount = this.common.calculate(totalPledgeAmount,this.pledgeNum,0,'divide');
      }
      this.totalPayment= result;
      return result
    }
  },
  methods: {
    //质押份数输入
    onChangeNum(val) {
      let num=val;
      this.pledgeNum=num;
    },
    //质押天数 （质押周期）
    // handlerAdd(i) {
    //   if(i==1){
    //     this.pledgeNum<2?this.pledgeNum=1:this.pledgeNum--;
    //   }else{
    //     this.pledgeNum++;
    //   }
    // },
    //质押金额
    onChange() {
      if (this.onePledgeAmount < parseFloat(this.productInfo.priceMin)) {
        this.$message.error(this.$t("market.desc34", { n1: this.productInfo.priceMin }));
        this.onePledgeAmount = parseFloat(this.productInfo.priceMin);
      } else if (this.onePledgeAmount > parseFloat(this.productInfo.priceMax)) {
        this.$message.error(this.$t("market.desc35", { n1: this.productInfo.priceMax }));
        this.onePledgeAmount = parseFloat(this.productInfo.priceMax);
      } 
    },
    //忘记资金密码
    handlerLogpsd() {
      this.showTrade = false;
      this.handlerClose(0);
      if(localStorage.getItem('ismobile') == 1) {
        this.$router.push('/user/info?id=2')
      } else {
        bus.emit("hadleropen", 1);
      }
    },
    //设置资金密码去
    handlerSubmit(i) {
      this.showTrade = false;
      this.handlerClose(0);
      if (localStorage.getItem('ismobile') == 1) {
        if (i == 1) {
          this.$router.push('/user/info?id=2')
        } else {
          this.$router.push('/user/info?id=4')
        }
      } else {
        bus.emit("hadleropen", i);
      }
      return;
    },
    
    //切换背景图
    handlerSwitch() {
      this.startId == 1 ? (this.startId = 2) : (this.startId = 1);
    },
    //切换账号
    handlerTab(i) {
      this.onePledgeAmount = "";
      this.payact = i;

      this.$post(this.URL.assets.view, {
        accountType:i,
        relationCoinId: this.productInfo?.payCoinId
      }).then((res) => {
        if (res.code == 0) {
          this.acountBalance = res.data.balance;
        }
      });
    },
    //获取商品信息
    getProductInfo() {
      this.levelId = this.productId;
      this.$get(this.URL.stake.productInfo, {
        productId: this.productId,
      }).then((res) => {
        if (res.code == 0) {
          let imgUrl = '';
          if(res?.data?.id==1){
            imgUrl=productBj1; 
          }else if(res?.data?.id==2){
            imgUrl=productBj2; 
          }else if(res?.data?.id==3){
            imgUrl=productBj3; 
          }else{
            imgUrl=productBj4; 
          };
          this.productInfo = res?.data;
          this.bjIco = imgUrl;
          this.handlerTab(1);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    
    finish(val) {
      this.password = val;
    },
    finish1(val) {
      if (val == 0) {
        this.password = "";
      }
    },
    handlerBuy() {
      if (this.password == "") {
        this.$message.error(this.$t("planet.desc17"));
        return;
      }
      if(this.loading){
        return;
      }
      this.loading = true;
      this.$post(this.URL.stake.orderBuy, {
        productId:this.productId,
        investAmount:this.onePledgeAmount,
        totalAmount:this.totalPayment,
        lockType:this.lockType,
        amountAccountType:this.payact,
        password:this.common.mdpassword(this.password),
      }).then((res) => {
        if (res.code == 0) {
          this.showpsd = false;
          this.showSuccess = true;
        } else {
          this.loading = false;
          this.password = "";
          this.$refs.PsdInput.reset();
          this.$message.error(res.message);
        }
      });
    },
    showPay() {
      if (this.onePledgeAmount == "") {
        this.$message.error(this.$t("market.desc33"));
        return;
      }
      
      if (!this.userInfo.hasSetPassword) {
        this.showTrade = true;
        return;
      }
      if (this.onePledgeAmount < parseFloat(this.productInfo.priceMin)) {
        this.$message.error(this.$t("stake.desc91",{n1:this.productInfo.priceMin}));
        return;
      }
      if (this.onePledgeAmount > parseFloat(this.productInfo.priceMax)) {
        this.$message.error(this.$t("stake.desc92",{n1:this.productInfo.priceMax}));
        return;
      }
      this.showpsd = true;
      this.password = "";
      this.loading = false;
    },
    
    handlerCheck() {
      this.check ? (this.check = false) : (this.check = true);
    },
    handlerClose(i) {
      this.showSuccess = false;
      this.showCon = false;
      setTimeout(() => {
        this.$emit("handlerClose", i);
      }, 500);
    }
    
  },
};
</script>

<style lang="less" scoped>
.nodewrappar {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 997;
  background: url("../public/images/land/bigbg1.png") top no-repeat;
  background-size: cover;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 0px; //修改滚动条宽度
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #666666;
  }

  .stardetails-bg {
    position: relative;
    width:1024px;
    height: fit-content;
    margin: 117px auto 0;
    background: url("../public/images/land/samllbg1.png") top no-repeat;
    background-size: 100% 96%;
    display: flex;
    justify-content: center;
    .closeicon {
      position: absolute;
      top: 18px;
      left: 0;
      width: 44px;
      height: 44px;
      background: url("../public/images/new/close.png") center no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      z-index: 56;
    }

    .slider-left {
      flex: 0 0 471px;
      margin: 0 -110px 0 23px;

      .left-name {
        width: 360px;
        position: relative;
        margin: 120px 0 0;
        font-size: 24px;
        color: #fff;

        &::after {
          content: "";
          position: absolute;
          bottom: -28px;
          left: 73px;
          width: 472px;
          height: 31px;
          background: url("../public/images/land/line1.png") center no-repeat;
          background-size: 100% 100%;
        }
      }

      .nameline2 {
        &::after {
          background: url("../public/images/land/line2.png") center no-repeat;
          background-size: 100% 100%;
        }
      }

      .nameline3 {
        &::after {
          background: url("../public/images/land/line3.png") center no-repeat;
          background-size: 100% 100%;
        }
      }

      .nameline4 {
        &::after {
          background: url("../public/images/land/line4.png") center no-repeat;
          background-size: 100% 100%;
        }
      }

      .nameline5 {
        &::after {
          background: url("../public/images/land/line5.png") center no-repeat;
          background-size: 100% 100%;
        }
      }

      .nameline6 {
        &::after {
          background: url("../public/images/land/line6.png") center no-repeat;
          background-size: 100% 100%;
        }
      }

      .nameline7 {
        &::after {
          background: url("../public/images/land/line7.png") center no-repeat;
          background-size: 100% 100%;
        }
      }

      .morename {
        font-size: 18px;

        &::after {
          background: none;
        }
      }

      .market-info {
        margin: 90px 0 0;
        width: 431px;
        padding: 54px 0 56px 24px;
        background: url("../public/images/land/textbg1.png") center no-repeat;
        background-size: 100% 100%;
        flex-wrap: wrap;

        .info-desc {
          flex: 0 0 144px;
          font-size: 12px;
          color: rgba(255, 255, 255, 0.8);
          line-height: 12px;

          &:nth-child(-n + 4) {
            margin-bottom: 32px;
          }

          &:last-child {
            flex: 0 0 125px;
          }

          div {
            img {
              width: 12px;
              height: 12px;
              margin-left: 4px;
              cursor: pointer;
            }

            span {
              display: none;
            }
          }

          p {
            font-size: 20px;
            margin-top: 13px;
            color: #fff;
            line-height: 20px;
          }
        }
      }

      .textbg2 {
        background: url("../public/images/land/textbg2.png") center no-repeat;
        background-size: 100% 100%;
      }

      .textbg3 {
        background: url("../public/images/land/textbg3.png") center no-repeat;
        background-size: 100% 100%;
      }

      .textbg4 {
        background: url("../public/images/land/textbg4.png") center no-repeat;
        background-size: 100% 100%;
      }

      .textbg5 {
        background: url("../public/images/land/textbg5.png") center no-repeat;
        background-size: 100% 100%;
      }

      .textbg6 {
        background: url("../public/images/land/textbg6.png") center no-repeat;
        background-size: 100% 100%;
      }

      .textbg7 {
        background: url("../public/images/land/textbg7.png") center no-repeat;
        background-size: 100% 100%;
      }

      .marketbig-info {
        margin-top: 8px;
        width: 435px;
        height: 516px;
        background: url("../public/images/land/leftbg.png") center no-repeat;
        background-size: 100% 100%;

        .info-name {
          padding: 20px 0 0 24px;
          font-size: 16px;
          color: #d2fb71;
          line-height: 40px;
        }

        .info-user {
          margin: 11px 24px 16px;
          font-size: 14px;
          line-height: 14px;
          color: rgba(255, 255, 255, 0.5);

          span {
            color: #fff;
          }
        }

        .info-subtitle {
          width: 308px;
          padding: 19px 24px 0;
          font-size: 14px;
          height: 147px;
          color: rgba(255, 255, 255, 0.8);
        }

        .info-list {
          margin-top: 38px;
          width: 431px;
          flex-wrap: wrap;
          padding: 0 24px;

          .info-desc {
            flex: 0 0 144px;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.8);
            line-height: 12px;

            &:nth-child(-n + 4) {
              margin-bottom: 20px;
            }

            &:last-child {
              flex: 0 0 125px;
            }

            div {
              img {
                width: 12px;
                height: 12px;
                margin-left: 4px;
                cursor: pointer;
              }

              span {
                display: none;
              }
            }

            p {
              font-size: 20px;
              margin-top: 13px;
              color: #fff;
              line-height: 20px;
            }
          }
        }
      }
    }

    .slider-center {
      position: relative;
      flex: 0 0 520px;
      width: 520px;
      min-height: 520px;
      margin-top: 70px;

      .center-top {
        width: 140px;
        height: 140px;
        margin: 0 auto;

        img {
          width: 140px;
          height: 140px;
        }
      }

      .center-switch {
        position: absolute;
        top: 140px;
        left: 50%;
        width: 44px;
        height: 44px;
        margin-left: -22px;
        background: url("../public/images/land/switch1.png") center no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        z-index: 6;
      }

      .switch2 {
        background: url("../public/images/land/switch2.png") center no-repeat;
        background-size: 100% 100%;
      }

      .switch3 {
        background: url("../public/images/land/switch3.png") center no-repeat;
        background-size: 100% 100%;
      }

      .switch4 {
        background: url("../public/images/land/switch4.png") center no-repeat;
        background-size: 100% 100%;
      }

      .switch5 {
        background: url("../public/images/land/switch5.png") center no-repeat;
        background-size: 100% 100%;
      }

      .switch6 {
        background: url("../public/images/land/switch6.png") center no-repeat;
        background-size: 100% 100%;
      }

      .switch7 {
        background: url("../public/images/land/switch7.png") center no-repeat;
        background-size: 100% 100%;
      }

      .center-bottom {
        width: 520px;
        height: 520px;
        margin-top: 60px;
        margin-left: 60px;

        img {
          width: 520px;
          height: 520px;
        }
      }
    }

    .slider-right {
      flex: 0 0 320px;
      margin: 63px 0 0 34px;

      .right-name {
        margin-bottom: 16px;
        font-size: 16px;
        color: #fff;
        line-height: 18px;
        justify-content: space-between;
        p{
          color: rgba(255, 255, 255, 0.4);
        }
      }
      .right-numinput{
        margin: 16px 0;
        border: 1px solid rgba(255, 255, 255, 0.20);
        box-sizing: border-box;
        // gap:20px;

        .lockType-card{
          height: 48%;
          line-height: 48px;
          padding: 0 5px;
          color: #fff;
          cursor: pointer;
        }
        .lockType-active{
          color: rgb(201, 250, 91);
        }
        // div{
        //   flex: 0 0 52px;
        //   width: 52px;
        //   height: 52px;
        //   font-size: 26px;
        //   color: #D9D9D9;
        //   text-align: center;
        //   line-height: 52px;
        //   cursor: pointer;
        // }
        // input{
        //   flex: 1;
        //   height: 52px;
        //   font-size: 16px;
        //   color: #FFFFFF;
        //   text-align: center;
        //   border-left: 1px solid rgba(255, 255, 255, 0.2);
        //   border-right: 1px solid rgba(255, 255, 255, 0.2);
        // }
      }
      .right-stock{
        text-align: right;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.4);
      }

      .right-tab {
        margin-bottom: 20px;
        justify-content: space-between;

        div {
          flex: 0 0 154px;
          width: 154px;
          height: 56px;
          background: url("../public/images/land/textbtn1.png") center no-repeat;
          background-size: 100% 100%;
          font-size: 15px;
          color: #ffffff;
          cursor: pointer;

          &:hover {
            background: url("../public/images/land/textbtn1_on.png") center no-repeat;
            background-size: 100% 100%;
            color: #000000;
          }
        }

        .tabbgon1 {
          background: url("../public/images/land/textbtn1_on.png") center no-repeat;
          background-size: 100% 100%;
          color: #000000;
        }

        .tabbg2 {
          background: url("../public/images/land/textbtn2.png") center no-repeat;
          background-size: 100% 100%;

          &:hover {
            background: url("../public/images/land/textbtn2_on.png") center no-repeat !important;
            background-size: 100% 100%;
          }
        }

        .tabbgon2 {
          background: url("../public/images/land/textbtn2_on.png") center no-repeat;
          background-size: 100% 100%;
          color: #000000;

          &:hover {
            background: url("../public/images/land/textbtn2_on.png") center no-repeat !important;
            background-size: 100% 100%;
          }
        }

        .tabbg3 {
          background: url("../public/images/land/textbtn3.png") center no-repeat;
          background-size: 100% 100%;

          &:hover {
            background: url("../public/images/land/textbtn3_on.png") center no-repeat !important;
            background-size: 100% 100%;
          }
        }

        .tabbgon3 {
          background: url("../public/images/land/textbtn3_on.png") center no-repeat;
          background-size: 100% 100%;
          color: #000000;

          &:hover {
            background: url("../public/images/land/textbtn3_on.png") center no-repeat !important;
            background-size: 100% 100%;
          }
        }

        .tabbg4 {
          background: url("../public/images/land/textbtn4.png") center no-repeat;
          background-size: 100% 100%;

          &:hover {
            background: url("../public/images/land/textbtn4_on.png") center no-repeat !important;
            background-size: 100% 100%;
          }
        }

        .tabbgon4 {
          background: url("../public/images/land/textbtn4_on.png") center no-repeat;
          background-size: 100% 100%;
          color: #000000;

          &:hover {
            background: url("../public/images/land/textbtn4_on.png") center no-repeat !important;
            background-size: 100% 100%;
          }
        }

        .tabbg5 {
          background: url("../public/images/land/textbtn5.png") center no-repeat;
          background-size: 100% 100%;

          &:hover {
            background: url("../public/images/land/textbtn5_on.png") center no-repeat !important;
            background-size: 100% 100%;
          }
        }

        .tabbgon5 {
          background: url("../public/images/land/textbtn5_on.png") center no-repeat;
          background-size: 100% 100%;
          color: #000000;

          &:hover {
            background: url("../public/images/land/textbtn5_on.png") center no-repeat !important;
            background-size: 100% 100%;
          }
        }

        .tabbg6 {
          background: url("../public/images/land/textbtn6.png") center no-repeat;
          background-size: 100% 100%;

          &:hover {
            background: url("../public/images/land/textbtn6_on.png") center no-repeat !important;
            background-size: 100% 100%;
          }
        }

        .tabbgon6 {
          background: url("../public/images/land/textbtn6_on.png") center no-repeat;
          background-size: 100% 100%;
          color: #000000;

          &:hover {
            background: url("../public/images/land/textbtn6_on.png") center no-repeat !important;
            background-size: 100% 100%;
          }
        }

        .tabbg7 {
          background: url("../public/images/land/textbtn7.png") center no-repeat;
          background-size: 100% 100%;

          &:hover {
            background: url("../public/images/land/textbtn7_on.png") center no-repeat !important;
            background-size: 100% 100%;
          }
        }

        .tabbgon7 {
          background: url("../public/images/land/textbtn7_on.png") center no-repeat;
          background-size: 100% 100%;
          color: #000000;

          &:hover {
            background: url("../public/images/land/textbtn7_on.png") center no-repeat !important;
            background-size: 100% 100%;
          }
        }
        // .tabbgon9 {
        //   background: url("../public/images/land/textbtn1.png") center no-repeat;
        //   background-size: 100% 100%;
        //   color: #000000;

        //   &:hover {
        //     background: url("../public/images/land/textbtn1_on.png") center no-repeat !important;
        //     background-size: 100% 100%;
        //   }
        // }
      }

      .right-input {
        margin-bottom: 20px;
        padding: 12px 16px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-sizing: border-box;
        font-size: 16px;
        color: #ffffff;
        line-height: 28px;

        .payinfo {
          flex: 0 0 100%;

          img {
            width: 28px;
            height: 28px;
            margin-right: 8px;
          }
        }

        input {
          flex: 1;
          height: 28px;
          font-size: 16px;
          color: #fff;

          &::placeholder {
            color: rgba(255, 255, 255, 0.4);
          }
        }

        .payamount {
          padding: 12px 0;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          box-sizing: border-box;

          &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
          }

          img {
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }

          p {
            line-height: 20px;
          }

          div {
            flex: 1;
            font-size: 14px;
            line-height: 20px;
            text-align: right;
          }
        }
      }

      .moretips {
        margin-bottom: 20px;
        font-size: 12px;
        color: #e3e3e3;

        span {
          color: #c9fa5b;
          padding-left: 4px;
        }
      }

      .slider-tips {
        margin-bottom: 20px;
        font-size: 14px;
        color: #f8a430;
        line-height: 16px;

        span {
          padding-left: 5px;
          color: #c9fa5b;
          cursor: pointer;
        }
      }

      .paylist {
        margin-bottom: 20px;

        .list-info {
          justify-content: space-between;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.8);

          &:last-child {
            margin-top: 10px;
          }

          p {
            color: #fff;
          }
        }
      }

      .slider-check {
        padding-top: 12px;
        font-size: 13px;
        color: rgba(255, 255, 255, 0.6);
        line-height: 16px;

        img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
          cursor: pointer;
        }

        div {
          span {
            cursor: pointer;
          }

          .textcolor1 {
            color: #ffeba5;
          }

          .textcolor2 {
            color: #ffe279;
          }

          .textcolor3 {
            color: #c9fa5b;
          }

          .textcolor4 {
            color: #f98f2c;
          }

          .textcolor5 {
            color: #f599f6;
          }

          .textcolor6 {
            color: #40b1f0;
          }

          .textcolor7 {
            color: #30cdff;
          }
        }
      }

      .slider-btn {
        margin-top: 18px;
        width: 100%;
        height: 56px;
        background: url("../public/images/land/btnbg2.png") center no-repeat;
        background-size: 100% 100%;
        font-size: 16px;
        color: #000000;
        font-weight: 600;
        cursor: pointer;
      }

      .btnbg1 {
        background: url("../public/images/land/btnbg3.png") center no-repeat;
        background-size: 100% 100%;
      }

      .btnbg2 {
        background: url("../public/images/land/btnbg4.png") center no-repeat;
        background-size: 100% 100%;
      }

      .btnbg3 {
        background: url("../public/images/land/btnbg5.png") center no-repeat;
        background-size: 100% 100%;
      }

      .btnbg4 {
        background: url("../public/images/land/btnbg6.png") center no-repeat;
        background-size: 100% 100%;
      }

      .btnbg6 {
        background: url("../public/images/land/btnbg7.png") center no-repeat;
        background-size: 100% 100%;
      }

      .btnbg7 {
        background: url("../public/images/land/btnbg8.png") center no-repeat;
        background-size: 100% 100%;
      }

      .greybtn {
        background: url("../public/images/land/btnbg1.png") center no-repeat;
        background-size: 100% 100%;
      }
    }

    .slider-right1 {
      flex: 0 0 354px;
      margin-top: 81px;
      padding: 40px 32px 0;
      background: url("../public/images/land/rightbg.png") top no-repeat;
      background-size: auto;

      .land-list {
        padding: 40px 0 0;
        flex-wrap: wrap;

        .land-list-info {
          margin-top: 32px;
          flex: 0 0 50%;
          font-size: 12px;
          color: rgba(255, 255, 255, 0.8);
          line-height: 12px;

          &:nth-child(-n + 2) {
            margin-top: 0;
          }
          div{
            img{
              width: 12px;
              height: 12px;
              cursor: pointer;
              margin-left: 4px;
            }
          }
          p {
            margin-top: 13px;
            font-size: 20px;
            color: #fff;
            line-height: 20px;
          }
        }
      }

      .land-btn {
        margin-top: 106px;
        width: 100%;
        height: 56px;
        background: url("../public/images/land/btnbg4.png") center no-repeat;
        background-size: 100% 100%;
        font-size: 16px;
        color: #000000;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
}

.bg1 {
  background: url("../public/images/stake/bigbg1.svg") top no-repeat;
  background-size: cover;
}

.bg2 {
  background: url("../public/images/stake/bigbg2.svg") top no-repeat;
  background-size: cover;
}

.bg3 {
  background: url("../public/images/stake/bigbg3.svg") top no-repeat;
  background-size: cover;
}

.bg4 {
  background: url("../public/images/stake/bigbg4.svg") top no-repeat;
  background-size: cover;
}

.bg6 {
  background: url("../public/images/land/bigbg6.png") top no-repeat;
  background-size: cover;
}

.bg7 {
  background: url("../public/images/land/bigbg7.png") top no-repeat;
  background-size: cover;
}

::v-deep .el-dropdown {
  width: 100%;
  border: 0 !important;

  .el-dropdown-link {
    position: relative;
    width: 100%;
    border: 0 !important;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      top: 11px;
      right: 0;
      width: 10px;
      height: 6px;
      background: url("../public/images/land/downicon.png") center no-repeat;
      background-size: 100% 100%;
    }
  }
}

.payinfo {
  flex: 0 0 100%;
  width: 100%;
  font-size: 16px;
  color: #ffffff;
  line-height: 28px;

  img {
    width: 28px;
    height: 28px;
    margin-right: 8px;
  }
}

.payinfo1 {
  width: 285px;
  font-size: 16px;
  color: #ffffff;
  line-height: 28px;
  justify-content: space-between;

  img {
    width: 28px;
    height: 28px;
    margin-right: 8px;
  }

  .inputname {
    justify-content: flex-end;
  }
}

.inputimg {
  margin-right: 8px;

  img {
    width: 28px;
    height: 28px;

    &:last-child {
      margin-left: -14px;
    }
  }
}

.inputname {
  flex: 1;
  font-size: 16px;
  color: #ffffff;
  line-height: 28px;

  span {
    padding: 0 4px;
  }
}

.modal-con {
  position: relative;
  padding: 32px;
  width: 480px;
  .close {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .modal-title {
    margin-bottom: 32px;
    font-size: 20px;
    color: #ffffff;
  }
  .modal-desc {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
  }
  .divestment-list {
    padding: 20px;
    border-radius: 8px;
    background: #242424;
    flex-wrap: wrap;

    .divestment-info {
      margin-bottom: 16px;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      line-height: 14px;

      &:last-child {
        margin-bottom: 0;
      }

      div {
        flex: 1;
      }

      p {
        flex: 0 0 40%;
        color: #fff;
        text-align: right;
      }
    }
  }
  .modal-tipsname {
    margin: 21px 0 11px;
    font-size: 14px;
    color: #fff;
    line-height: 16px;

    img {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
  }
  .modal-tips1 {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
    line-height: 14px;

    div {
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .modal-input {
    margin: 20px 0 16px;
  }
  .modal-tips {
    font-size: 14px;
    color: #eb4343;
  }
  .modal-btncon {
    margin-top: 24px;
    height: 50px;
    background: url("../public/images/new/btnbg16.png") center no-repeat;
    background-size: 100% 100%;
    font-size: 16px;
    color: #000000;
    cursor: pointer;
  }
  .modal-btn {
    margin-top: 32px;
    justify-content: space-between;

    div {
      flex: 0 0 197px;
      height: 50px;
      background: url("../public/images/new/btnbg14.png") center no-repeat;
      background-size: 100% 100%;
      font-size: 18px;
      color: #ffffff;
      cursor: pointer;

      &:last-child {
        background: url("../public/images/new/btnbg15.png") center no-repeat;
        background-size: 100% 100%;
        color: #000000;
      }
    }
  }
  .forgottitle{
    margin-top: 24px;
    color: #c9fa5b;
    font-size: 16px;
    cursor: pointer;
    text-align: right;
  }
}

.h5top {
  display: none;
}

.sales-end {
  width: 98px;
  height: 80px;
  margin: 0 auto;
  background: url("../public/images/land/tagbg.png") center no-repeat;
  background-size: 100% 100%;
  font-size: 16px;
  color: #ffffff;
  z-index: 3;

  p {
    text-align: center;
    line-height: 80px;
    transform: rotate(-11deg);
  }
}

.endbg1 {
  background: url("../public/images/land/tagbg2.png") center no-repeat;
  background-size: 100% 100%;
}

.endbg2 {
  background: url("../public/images/land/tagbg1.png") center no-repeat;
  background-size: 100% 100%;
}

.endbg3 {
  background: url("../public/images/land/tagbg3.png") center no-repeat;
  background-size: 100% 100%;

  p {
    color: #000000;
  }
}
.ratecon {
  width: 816px;

  .rate-list {
    font-size: 16px;
    color: #FFFFFF;

    div {
      margin-bottom: 24px;
    }
  }

  .rate-table {
    border: 1px solid rgba(255, 255, 255, 0.08);
    box-sizing: border-box;

    .table-thead {
      background: rgba(201, 250, 91, 0.10);

      div {
        flex: 0 0 25%;
        height: 39px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.85);
        text-align: center;
        border-right: 1px solid rgba(255, 255, 255, 0.08);

        &:last-child {
          border-right: 0;
        }
      }
    }

    .tbody-info {
      border-top: 1px solid rgba(255, 255, 255, 0.08);

      div {
        flex: 0 0 25%;
        height: 39px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.85);
        text-align: center;
        border-right: 1px solid rgba(255, 255, 255, 0.08);
        line-height: 39px;

        &:last-child {
          border-right: 0;
        }
      }
    }
  }

  .ratebtn {
    width: 500px;
    height: 55px;
    margin: 24px auto 0;
    border-radius: 6px;
    background: #C9FA5B;
    font-size: 20px;
    color: #282626;
    font-weight: 600;
    cursor: pointer;
  }
}
@media (max-width: 1200px) {
  .nodewrappar {
    background: url("../public/images/land/h5bg1.png") center no-repeat;
    background-size: 100% 100%;

    .stardetails-bg {
      width: calc(100vw - 8px);
      flex-wrap: wrap;
      margin: 64px auto 0;
      background: none !important;

      .closeicon {
        position: absolute;
        top: 20px;
        left: inherit;
        right: 0;
      }

      .slider-left {
        flex: 0 0 100%;
        margin: 0;

        .left-name {
          margin-top: 0;
          width: 100%;

          &:after {
            width: 0;
            background: none !important;
          }
        }

        .marketbig-info {
          width: 100%;
          height: auto;
          margin: 0;
          background: none !important;

          .info-wrap {
            margin: 20px 0;
            padding: 20px 16px;
            border: 1px solid rgba(255, 255, 255, 0.1);
            box-sizing: border-box;
          }

          .info-name {
            padding: 0 0 16px 0;
            line-height: inherit;
          }

          .info-user {
            margin: 0;
            padding-bottom: 16px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          }

          .info-subtitle {
            width: 100%;
            height: inherit;
            padding: 16px 0 0;
          }

          .info-list {
            width: 100%;
            margin-top: 0;
            padding: 20px 16px;
            border: 1px solid rgba(255, 255, 255, 0.1);
            box-sizing: border-box;

            .info-desc {
              flex: 0 0 33.3%;

              &:nth-child(-n + 3) {
                margin-bottom: 24px;
              }

              &:last-child {
                flex: 0 0 33.3%;
              }

              div {
                span {
                  display: inline-block;
                }
              }

              p {
                margin-top: 6px;
                font-size: 18px;
                line-height: 18px;

                span {
                  display: none;
                }
              }
            }
          }
        }

        .market-info {
          width: 100%;
          margin-top: 20px;
          padding: 20px 16px;
          border: 1px solid rgba(255, 255, 255, 0.1);
          box-sizing: border-box;
          background: none;

          .info-desc {
            flex: 0 0 33.3%;
            margin-bottom: 0;

            &:nth-child(-n + 4) {
              margin-bottom: 0;
            }

            &:nth-child(-n + 3) {
              margin-bottom: 24px;
            }

            &:last-child {
              flex: 0 0 33.3%;
            }

            div {
              span {
                display: inline-block;
              }
            }

            p {
              margin-top: 6px;
              font-size: 18px;
              line-height: 18px;

              span {
                display: none;
              }
            }
          }
        }
      }

      .slider-center {
        display: none;
        width: 100%;
        flex: 0 0 100%;
        width: 100%;
        margin-top: 12px;
        min-height: inherit;

        .center-top {
          width: 80px;
          height: 80px;

          img {
            width: 80px;
            height: 80px;
          }
        }

        .center-switch {
          top: 82px;
        }

        .center-bottom {
          width: 340px;
          height: 340px;
          margin: 0 auto;

          img {
            width: 340px;
            height: 340px;
          }
        }
      }

      .slider-right {
        flex: 0 0 100%;
        margin: 20px 0 0;

        .right-name {
          margin-bottom: 10px;
          font-size: 16px;
          line-height: 16px;
        }

        .right-tab {
          justify-content: flex-start;

          div {
            margin-right: 14px;
          }
        }

        .slider-btn {
          max-width: 320px;
          margin: 18px auto 0;
        }
      }

      .slider-right1 {
        flex: 0 0 100%;
        margin: 20px 0 0;
        padding: 0;
        background: none;

        .sales-end {
          display: none;
        }

        .land-list {
          padding: 20px 16px !important;
          border: 1px solid rgba(255, 255, 255, 0.1);
          box-sizing: border-box;

          .land-list-info {
            flex: 0 0 33.3%;
            margin-top: 24px;

            &:nth-child(-n + 3) {
              margin-top: 0;
            }

            p {
              margin-top: 6px;
              font-size: 18px;
              line-height: 18px;
            }
          }
        }

        .land-btn {
          margin-top: 24px;
        }
      }
    }
  }

  .modal-con {
    width: 100% !important;
    padding: 32px 16px;

    .modal-title {
      padding-right: 40px;
    }

    .close {
      right: 16px;
    }

    .modal-btn {
      div {
        flex: 0 0 49%;
        height: 44px;
      }
    }
  }

  .h5top {
    display: block !important;
  }

  .sales-end {
    position: absolute;
    top: 20px;
    left: 16px;
    width: 75px;
    height: 62px;

    p {
      line-height: 62px;
    }
  }

  .bg2 {
    background: url("../public/images/land/h5bg2.png") top no-repeat;
    background-size: cover;
  }

  .bg3 {
    background: url("../public/images/land/h5bg3.png") top no-repeat;
    background-size: cover;
  }

  .bg4 {
    background: url("../public/images/land/h5bg4.png") top no-repeat;
    background-size: cover;
  }

  .bg5 {
    background: url("../public/images/land/h5bg5.png") top no-repeat;
    background-size: cover;
  }

  .bg6 {
    background: url("../public/images/land/h5bg6.png") top no-repeat;
    background-size: cover;
  }

  .bg7 {
    background: url("../public/images/land/h5bg7.png") top no-repeat;
    background-size: cover;
  }
}

@media (max-width: 767px) {
  .nodewrappar {
    .stardetails-bg {
      margin: 0 auto;
    }
  }
}
</style>
